/**
* @file img Class.
* LasyLoad & change 'pc' or 'sp' img.
* ------------------------------
* @exsample
* == before html.
* <img id="img" src="spacer.gif" data-sp-src="sp.gif" data-pc-src="pc.gif">
* == js file.
* new Img($('#img'));
* img.setDevice('pc');
* == after html.
* <img id="img" src="pc.gif" data-sp-src="sp.gif" data-pc-src="pc.gif">
*/
module.exports = ((win, doc, $) => {
  'use strict';

  /*
  * @type {object} - DATA
  * */
  const DATA_NAME = {
    common: 'data-src',
    pc: 'data-pc-src',
    sp: 'data-sp-src'
  };
  const DELAY = 100;
  const LOAD_CLASS_NAME = 'load';


  return class Img {
    /*
    * @constructor
    * @param {jQuery} img - img Element.
    * @param {string} state - default state.
    * */
    constructor(img, state, __opt__){
      if(!(this instanceof Img)) {
        return new Img(img, __opt__);
      }
      this.img = img,
      this.load_class_name = LOAD_CLASS_NAME;
      this.data_name = DATA_NAME;
      this.delay = DELAY;
      this.state = state||false;
      this.src={};

      _.isObject(__opt__) && _.extend(this, __opt__);

      this.init();
    }

    /*
    * initialize
    * */
    init() {
      let name = [];
      _.each(this.data_name, (v, k) => {
        name[name.length] = 'load-' + k;
        this.src[k] = this.img.attr(v);
      }, this);

      this.img.hasClass(this.load_class_name) && this.img.addClass(name.join(' '));
    }

    /*
    * set img type.
    * @param {string} key - state;
    * */
    setState(key){
      if(!this.src[key]) { key = 'common'; }
      if(this.state === key) { return; }

      this.state = key;
      this.set();
    }

    /*
    * set src.
    * */
    set() {
      let src = this.src[this.state];
      if(!src || this.img.hasClass('load-' + this.state)) { return; }

      this.img.removeClass('load-' + this.state);
      this.img.attr('src', src);
    }

    /*
    * loading img.
    * @return {object} - jQuery Deferred object.
    * */
    load() {
      let d = new $.Deferred();
      let src = this.src[this.state];
      let img;

      //- already loading.
      if(!this.img.hasClass('load-' + this.state)) {
        setTimeout(function(){ d.resolve(); }, 0);
        return d;
      }

      img = $('<img />');

      //- start loading.
      img.on('load.img', ()=>{
        img.off('load.img');
        img.off('error.img');
        img = void 0;
        d.resolve();

      }).on('error.img', ()=>{
        img.off('load.img');
        img.off('error.img');
        img = void 0;
        d.reject();

      }).attr('src', src);

      return $.when(
        d,
        (function(self){
          var d = new $.Deferred();
          setTimeout(function(){ d.resolve(); }, self.delay);
          return d;
        })(this)
      );
    }

  }

})(window, document, window['jQuery']);
