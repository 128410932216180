/**
* @file Mediaquery Class.
* add <html> class.
*/
module.exports = (function(win, doc, $) {
  'use strict';

  const EVENT_PREFIX = 'media';
  const DEFAULT_DATA = {
    539: 'media-sp media-539',
    767: 'media-sp media-tablet media-767',
    1083: 'media-pc media-tablet media-1083',
    max: 'media-pc media-max'
  };
  const MAX_NAME = 'max';

  let h_;
  let w_;


  return class Mediaquery {

    /*
    * @constructor
    * @param {Array} point - break point.
    * */
    constructor(data, __opt__){
      if(!(this instanceof Mediaquery)) {
        return new Mediaquery(data, __opt__);
      }

      !w_ && (w_ = $(win));
      !h_ && (h_ = $('html'));

      this.data = data||DEFAULT_DATA;
      this.break_point = _.keys(this.data);
      this.max_name = MAX_NAME;
      this.event_name = EVENT_PREFIX;
      this.callback_func = {};

      _.isObject(__opt__) && _.extend(this, __opt__);
      this.init();
    }

    /*
    * initialize.
    * */
    init(){
      w_.on('resize', _.bind(this.check, this));
      this.check();
    }

    /*
    * get media state.
    * @return {string} - now state
    * */
    getNow(){ return this.query; }

    /*
    * check browser size.
    * */
    check(){
      let w = w_.width();

      let now = this.data[this.query];
      let n = _.detect(this.break_point, function(n){
        return n >= w;
      });
      let next;

      n || (n = this.max_name);

      if(this.query === n) {
        return;
      }

      next = this.data[n];
      this.query = n;

      h_.removeClass(now);
      h_.addClass(next);
      this.change();
    }

    /*
    * add change Event.
    * @param {function} fn. - callback function.
    * @return {string} - event name.
    * */
    on_change(fn){
      let name = _.uniqueId(this.event_name);
      this.callback_func[name] = fn;
      return name;
    }

    /*
    * remove change Event.
    * @param {string} name. - register event name.
    * */
    off_change(name){
      delete this.callback_func[name];
    }

    /*
    * callback change Event.
    * */
    change(){
      _.each(this.callback_func, (v) => { v(); });
    }
  }

})(window, document, window['jQuery']);
