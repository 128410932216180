import Mediaquery from '_shared_class/mediaquery';

;((win, doc, $) => {
  'use strict';
  if (!$) { return; }

  const ua = require('_shared_class/ua');
  const Image = require('_shared_class/img');

  let html_class = 'is-js';

  if(ua.isSP) {
    html_class += ' is-sp';
  }else {
    html_class += ' is-pc';
  }

  if(ua.isAndroid) {
    html_class += ' is-android';
  }

  if(ua.isIOS) {
    html_class += ' is-ios';
  }

  $('html').addClass(html_class);

  const mediaquery = $.fn._mediaquery = new Mediaquery({
    750: 'media-sp',
    max: 'media-pc'
  },{
    getMedia: function(){
      return this.getNow() === '750' ? 'sp' : 'pc';
    }
  });

  $(() => {
    let imgs = [];

    _.each($('img'), (img) => {
      img = $(img);
      if(img.attr('data-pc-src') && img.attr('data-sp-src')){
        imgs[imgs.length] = new Image(img);
      }
    });

    //- mediaによる画像のリプレイス
    imgs.length && mediaquery.on_change(() => {
      let now = mediaquery.getMedia();
      _.each(imgs, (img) => { img.setState(now); });
    });

    mediaquery.change();
  });

})(window, document, window['jQuery']);
