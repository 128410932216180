module.exports = (() => {
  'use strict';

  /*
  * Include ua module.
  * */
  let UA = require('ua-parser-js');
  let ua = (new UA()).getResult();
  let isAndroid = ua.os.name === 'Android';
  let isIOS = ua.os.name === 'iOS';
  let isSP = isAndroid || isIOS || false;
  let isIe = ua.browser.name === 'IE';
  let isltIe9 = isIe && (ua.browser.version - 0) < 9;
  let isIe9 = isIe && (ua.browser.version - 0) === 9;

  /*
  * API
  * */
  return {
    isAndroid,
    isIOS,
    isSP,
    isIe,
    isltIe9,
    isIe9
  };
})();

